<template>
    <div class="">
        <div class="container mx-auto px-4 md:px-6 lg:px-8 py-4 lg:py-8">
            <nuxt-link to="/">
                <img
                    width="160"
                    height="47"
                    src="https://res.cloudinary.com/avoya-travel/image/upload/f_auto,q_auto/v1674236163/revagency-com/revagency.png"
                    alt="RevAgency Logo"
                />
            </nuxt-link>
        </div>
    </div>
</template>

<script setup></script>

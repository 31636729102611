<template>
    <footer class="bg-slate-100">
        <div class="container mx-auto px-4 md:px-6 lg:px-8">
            <div class="py-16">
                <img
                    class="mt-6 mx-auto"
                    width="160"
                    height="47"
                    src="https://res.cloudinary.com/avoya-travel/image/upload/f_auto,q_auto/v1674236163/revagency-com/revagency.png"
                    alt="RevAgency Logo"
                />
            </div>
            <div class="flex flex-col border-t border-slate-300 py-10">
                <p class="mt-6 text-sm text-slate-700 sm:mt-0">
                    &copy; 2000-{{ new Date().getFullYear() }} RevAgency&trade;.
                    Patent Pending.<br />
                    All information is confidential and is not to be reproduced
                    in any format.<br />
                    <nuxt-link to="/privacy-policy/" class="link-gray">
                        Privacy Policy
                    </nuxt-link>
                </p>
            </div>
        </div>
    </footer>
</template>

<script setup></script>
